import * as React from 'react';
import { green, pink } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Grid, Divider } from '@mui/material';

export default function MobileMenu2() {
  return (
     
    <Grid container px={2}  mt={1}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={3}>
        <Box component={RouterLink}  to='/local-transfer' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1741523772/transfer_zo9eaw.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#4b0809'}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '11px'}}>Local Transfer</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/wire-transfer' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1741523772/money_pwxbyj.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#4b0809'}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '11px'}}>Wire Transfer</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/sepa-transfer' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1741523772/money-exchange_rsbquq.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#4b0809'}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '11px'}}>SEPA Transfer</Typography>   
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box component={RouterLink}  to='/transfers' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1741523772/bank-check_derdb6.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#4b0809'}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '11px'}}>History</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/wallet' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1741523772/cryptocurrency_1_rkg7bx.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#4b0809'}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '11px'}}>Crypto Deposit</Typography>   
        </Box>
      </Grid>

      

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/cards' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1741523771/credit-card_2_aja9ja.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#4b0809'}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '11px'}}>Cards</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/transfer-pin' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1741523771/security_adbzbh.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#4b0809'}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '11px'}}>Security</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/loans' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1741523771/loan_eorbk8.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#4b0809'}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '11px'}}>Loans</Typography>   
        </Box>
      </Grid>
      
    </Grid>
  );
}