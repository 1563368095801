import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector } from 'react-redux';
import Navigations from './Navigations';


import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';


import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';


import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import axios from "axios";
import TransferList from '../Transfers/TransferList';
const TransactionCharts = () => {

    const [ip, setIP] = useState("");
    const debitsAnalysis = useSelector((state) => state.chartsAnalysis.salesAnalysis);
    const creditstsAnalysis = useSelector((state) => state.chartsAnalysis.fundingsAnalysis);
    const user = useSelector((state) => state.user.user);

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: user.currency, currencySign: 'standard' }).format(amount);
    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);
    const loanBalance = useSelector((state) => state.wallet.loanBalance);
    
    let salesData = [];
    let fundingsData = [];

    debitsAnalysis.forEach(element => {
        salesData.push(element.amount);
    });

    creditstsAnalysis.forEach(element => {
        fundingsData.push(element.amount);
    });


    const series =  [{
        name: 'Total Debits',
        data: salesData
      },
      {
        name: 'Total Credits',
        data: fundingsData
      }
    ];

    const options = {
        chart: {
            foreColor: '#939c92',
          type: 'bar',
          height: 350,
        },
        
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        tooltip: {
            theme: 'dark'
          },
        xaxis: {
            theme: 'dark',
          categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
          title: {
            text: user.currency
          }
        },
        fill: {
          opacity: 1
        },
        
      };

      const getData = async () => {
        fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setIP(data.ip))
        .catch(error => console.log(error))
      };

      useEffect(() => {
        getData();
      }, []);

  return (
    <div style={{marginTop: '5px', marginBottom: '-75px'}}>        
        <Card sx={{marginX: '0px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography  ml={3} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'left', textTransform: 'capitalize'  }} gutterBottom variant="h6" component="div">
                        Account Overview ({user.currency})
                    </Typography>
                    <Typography ml={3} my={0} sx={{textAlign: 'left'}} variant="body2" color="text.secondary">
                        your account performance analysis for this year
                    </Typography>
                    <ReactApexChart options={options}  series={series} type="bar" height={350} />
                    </CardContent>
                </Card>
            
        
    </div>
  )
}

export default TransactionCharts
