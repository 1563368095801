import React from 'react'
import CoinMarket from './CoinMarket';
import WalletSlide from '../Wallet/WalletSlide';
import CardsSingle from '../Cards/CardsSingle';
import TransferList from '../Transfers/TransferList';
import TransactionCharts from './TransactionCharts';
import MobileMenu2 from './MobileMenu2';
import BalanceCard from './BalanceCard';
import InOutFlow from './InOutFlow';
import { Box, Divider } from '@mui/material';
import TransactionHistory from '../Transfers/TransactionHistory';
const Index = () => {
  return (
    <>
   <BalanceCard/>
    {/* <MobileMenu/> */}
    <InOutFlow/>
    <MobileMenu2/>
    <Divider sx={{marginBottom: '-20px'}} />
    <TransactionHistory/>
    <TransactionCharts/>
    {/* <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
    
    </Box> */}
    </>
  )
}

export default Index
