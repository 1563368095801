import React, {useEffect, useState, Fragment} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const InOutFlow = () => {

  const [buttonText, setbuttonText] = useState(null);

    const [ip, setIP] = useState("");
    const debitsAnalysis = useSelector((state) => state.chartsAnalysis.salesAnalysis);
    const creditstsAnalysis = useSelector((state) => state.chartsAnalysis.fundingsAnalysis);
    const user = useSelector((state) => state.user.user);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: user.currency, currencySign: 'standard' }).format(amount);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const loanBalance = useSelector((state) => state.wallet.loanBalance);

    const debits = useSelector((state) => state.history.debits);
    const credits = useSelector((state) => state.history.credits);

    const calTotal = (transactions) => {
        let amount = 0;
        transactions.forEach(transaction => {
           amount += parseFloat(transaction.amount); 
        });

        return amount;
    }

    let salesData = [];
    let fundingsData = [];

    debitsAnalysis.forEach(element => {
        salesData.push(element.amount);
    });

    creditstsAnalysis.forEach(element => {
        fundingsData.push(element.amount);
    });


    const series =  [{
        name: 'Total Debits',
        data: salesData
      },
      {
        name: 'Total Credits',
        data: fundingsData
      }
    ];

    const options = {
        chart: {
            foreColor: '#939c92',
          type: 'bar',
          height: 350,
        },
        
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        tooltip: {
            theme: 'dark'
          },
        xaxis: {
            theme: 'dark',
          categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
          title: {
            text: user.currency
          }
        },
        fill: {
          opacity: 1
        },
        
      };

      const getData = async () => {
        fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setIP(data.ip))
        .catch(error => console.log(error))
      };

      useEffect(() => {
        getData();
      }, []);

  return (
    <div style={{marginTop: '5px',}}>
       
        <Grid  container rowSpacing={2} sx={{paddingX: '10px'}} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>        
                <Grid item xs={6} md={3}>
                <Card sx={{marginX: '5px', padding: '5px', borderRadius: '9px', height: 'fit-content'}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center'}}>
                        
                        <CardMedia sx={{display: 'flex', justifyContent: 'center'}}>
                        <Avatar sx={{ bgcolor: '#ed1b24' , height: '34px', width: '34px'}}>
                            <KeyboardDoubleArrowDownIcon sx={{ color: '#ffffff', }}/>
                        </Avatar>
                        </CardMedia>

                        <Box sx={{padding: 0}}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                            {formatPrice(calTotal(credits))}
                        </Typography>

                        <Typography my={0} variant="caption">
                        Total Inflow
                        </Typography>
                        
                        </Box>
                    </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={3}>
                <Card sx={{marginX: '5px', padding: '5px', borderRadius: '9px', height: 'fit-content'}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center'}}>
                        
                        <CardMedia sx={{display: 'flex', justifyContent: 'center'}}>
                        <Avatar sx={{ bgcolor: '#ed1b24' , height: '34px', width: '34px'}}>
                            <KeyboardDoubleArrowUpIcon sx={{ color: '#ffffff', }}/>
                        </Avatar>
                        </CardMedia>

                        <Box sx={{padding: 0}}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                            {formatPrice(calTotal(debits))}
                        </Typography>

                        <Typography my={0} variant="caption">
                        Total Outflow
                        </Typography>
                        
                        </Box>
                    </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={3}>
                <Card sx={{marginX: '5px', padding: '5px', borderRadius: '9px', height: 'fit-content'}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center'}}>
                        
                        <CardMedia sx={{display: 'flex', justifyContent: 'center'}}>
                        <Avatar sx={{ bgcolor: '#ed1b24' , height: '34px', width: '34px'}}>
                            <CurrencyExchangeIcon sx={{ color: '#ffffff', }}/>
                        </Avatar>
                        </CardMedia>

                        <Box sx={{padding: 0}}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                            {formatPrice(subBal)}
                        </Typography>

                        <Typography my={0} variant="caption">
                        Cash Back
                        </Typography>
                        
                        </Box>
                    </Box>
                    </Card>
                </Grid>

                <Grid item xs={6} md={3}>
                <Card sx={{marginX: '5px', padding: '5px', borderRadius: '9px', height: 'fit-content'}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '5px', alignItems: 'center'}}>
                        
                        <CardMedia sx={{display: 'flex', justifyContent: 'center'}}>
                        <Avatar  sx={{ bgcolor: '#ed1b24' , height: '34px', width: '34px'}}>
                            <CreditScoreIcon sx={{ color: '#ffffff', }}/>
                        </Avatar>
                        </CardMedia>

                        <Box sx={{padding: 0}}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                            {formatPrice(loanBalance)}
                        </Typography>

                        <Typography my={0} variant="caption">
                        Loans
                        </Typography>
                        
                        </Box>
                    </Box>
                    </Card>
                </Grid>
            </Grid>
        
    </div>
  )
}

export default InOutFlow
